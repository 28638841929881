import "intersection-observer";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import { Controller } from "stimulus";
import Cookies from "js-cookie";
import LazyLoad from "vanilla-lazyload";
import playerjs from "player.js";

export default class extends Controller {
  static targets = [
    "body",
    "menu",
    "searchOverlay",
    "searchQuery",
    "footerLinks",
    "featuredVideoSentinel",
    "player",
  ];

  initialize() {
    let ll_threshold = 300; //default

    if (this.bodyTarget.classList.contains("body--mktg")) ll_threshold = 0;

    // lazy load
    this.ll = new LazyLoad({
      threshold: ll_threshold,
      elements_selector: ".lazy",
      callback_loaded: (element) => {
        if (element.classList.contains("loading")) {
          element.classList.remove("loading");
        } else {
          const loader = element.closest(".loading");
          if (loader) {
            loader.classList.remove("loading");
          }
        }
      },
      callback_error: (element) => {
        if (element.classList.contains("loading")) {
          element.classList.remove("loading");
        } else {
          const loader = element.closest(".loading");
          if (loader) {
            loader.classList.remove("loading");
          }
        }
      },
    });

    // ads video as content lauch
    const featuredVideoObserver = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.loadAdsVideo();
      }
    });

    if (this.hasFeaturedVideoSentinelTarget)
      featuredVideoObserver.observe(this.featuredVideoSentinelTarget);

    // check tcf
    this.checkTcReady();

    // player
    if (this.hasPlayerTarget) {
      this.player = new playerjs.Player(this.playerTarget);
    }
  }

  checkTcReady() {
    if (typeof __tcfapi !== "undefined") {
      __tcfapi("addEventListener", 2, (tcData, success) => {
        if (success && tcData.eventStatus === "useractioncomplete") {
          console.log("[ads-video] useractioncomplete");

          this.tcComplete = typeof tcData.addtlConsent !== "undefined";

          if (this.player) {
            this.player.send({ method: "startVideo" });
          }
        } else if (success && tcData.eventStatus === "tcloaded") {
          __tcfapi(
            "removeEventListener",
            2,
            (success) => {
              if (success) {
                console.log("[ads-video] tcloaded");

                this.tcComplete = typeof tcData.addtlConsent !== "undefined";

                if (this.player) {
                  this.player.send({ method: "startVideo" });
                }
              }
            },
            tcData.listenerId
          );
        } else {
          console.log("[ads-video] waiting for user action");
        }
      });
    } else {
      setTimeout(() => {
        this.checkTcReady();
      }, 200);
    }
  }

  updateLazyLoad() {
    this.ll.update();
  }

  vh() {
    const vh = window.innerHeight * 0.01;
    this.element.style.setProperty("--vh", `${vh}px`);
  }

  openMenu() {
    this.menuTarget.classList.add("menu--open");
    this.bodyNoScroll();
  }

  closeMenu() {
    this.menuTarget.classList.remove("menu--open");
    this.bodyScroll();
  }

  openSearch() {
    this.searchOverlayTarget.classList.add("is-open");
    setTimeout(() => {
      this.searchQueryTarget.focus();
    }, 500);

    this.bodyNoScroll();
  }

  closeSearch() {
    this.searchOverlayTarget.classList.remove("is-open");
    this.bodyScroll();
  }

  bodyNoScroll() {
    disableBodyScroll(this.bodyTarget);
  }

  bodyScroll() {
    enableBodyScroll(this.bodyTarget);
  }

  toggleFooterLinks(e) {
    e.currentTarget.classList.toggle("is-open");
    this.footerLinksTarget.classList.toggle("is-open");
  }

  onScroll() {
    if (this.scheduledAnimationFrame) {
      return;
    }

    this.scheduledAnimationFrame = true;
    window.requestAnimationFrame(() => this.loadAdsVideo());
  }

  loadAdsVideo() {
    if (!this.adsVideoLoaded && this.tcComplete) {
      this.adsVideoLoaded = true;

      fetch(`/ads/video/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: this.data.has("scrollParams")
          ? this.data.get("scrollParams")
          : null,
      })
        .then((response) => response.text())
        .then((html) => {
          document.body.insertAdjacentHTML("beforeend", html);
        });
    } else {
      this.scheduledAnimationFrame = false;
    }
  }

  closePopup() {
    document.querySelector(".popup.is-open").classList.remove("is-open");
  }

  closePopupStopPropagation(e) {
    e.stopPropagation();
  }

  exitPreview(e) {
    e.preventDefault();
    Cookies.remove("previewToken");
    window.location.href = "/";
  }
}
