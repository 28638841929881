import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["image"];

  goFull(event) {
    this.baseSrc = this.imageTarget.src;
    this.imageTarget.src = this.data.get("src");
    event.currentTarget
      .closest(".post__image")
      .classList.add("post__image--full");
  }

  exitFull(event) {
    this.imageTarget.src = this.baseSrc;
    event.currentTarget
      .closest(".post__image")
      .classList.remove("post__image--full");
  }
}
