import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
  static targets = ["sliderNetworks"];

  initialize() {
    if (this.hasSliderNetworksTarget) this.sliderNetworks();
  }

  sliderNetworks() {
    this.swiperNetworks = new Swiper(this.sliderNetworksTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true
      },
      slidesPerView: "auto",
      slidesPerGroup: 3,
      speed: 400,
      loop: false,
      navigation: {
        nextEl: ".networks__sliderbtn--next",
        prevEl: ".networks__sliderbtn--prev"
      },
        touchReleaseOnEdges: true,
        freeMode: true,
        freeModeMomentumBounce: false,
      breakpoints: {
        425: {
          slidesPerGroup: 4
        },
        600: {
          slidesPerGroup: 6
        },
        768: {
          slidesPerGroup: 10
        }
      },
      on: {
        transitionStart: function() {
          if (Modernizr.touchevents) this.navigation.nextEl.style.opacity = 0;
        }
      }
    });
  }
}
