import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["alpha"];

    openAlpha() {
        this.alphaTarget.classList.add("alpha--open");
    }

    closeAlpha() {
        this.alphaTarget.classList.remove("alpha--open");
    }
}