import { Controller } from "stimulus";
import queryString from "query-string";
import { createBrowserHistory } from "history";
import CustomEvent from "custom-event";
import InfiniteScroll from "infinite-scroll";

export default class extends Controller {
  static targets = [
    "advSearch",
    "filter",
    "options",
    "list",
    "filterGroup",
    "closeMobile",
    "suggestion",
  ];

  initialize() {
    if (!this.hasListTarget) return;

    this.endpoint = this.data.get("endpoint");

    if (document.querySelector(".pagination__next")) {
      this.infScroll = new InfiniteScroll(this.listTarget, {
        path: ".pagination__next",
        append: this.listTarget.dataset.append,
        hideNav: ".pagination",
        historyTitle: true,
      });

      this.infScroll.on("request", () => {
        this.listTarget.classList.add("loading");
      });

      this.infScroll.on("load", () => {
        this.listTarget.classList.remove("loading");
      });

      this.infScroll.on("append", () => {
        this.listTarget.parentElement.dispatchEvent(
          new CustomEvent("update-ll")
        );
      });
    }
  }

  openAdvSearch(event) {
    this.advSearchTarget.classList.add("is-open");
    event.currentTarget.dispatchEvent(new CustomEvent("body-noscroll"));
  }

  closeAdvSearch(event) {
    this.advSearchTarget.classList.remove("is-open");
    event.currentTarget.dispatchEvent(new CustomEvent("body-scroll"));
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  resetAll(event) {
    const history = createBrowserHistory();

    this.filterTargets.map((t) => t.dispatchEvent(new CustomEvent("reset")));

    history.replace({
      search: null,
    });

    if (this.hasAdvSearchTarget) {
      this.advSearchTarget.classList.remove("is-open");
      event.currentTarget.dispatchEvent(new CustomEvent("body-scroll"));
    }

    this.search();
  }

  applyAll() {
    this.filterTargets.map((t) => t.dispatchEvent(new CustomEvent("apply")));
    if (this.hasAdvSearchTarget) {
      this.advSearchTarget.classList.remove("is-open");
      event.currentTarget.dispatchEvent(new CustomEvent("body-scroll"));
    }
  }

  search() {
    const history = createBrowserHistory();
    const location = history.location;
    const parsed = queryString.parse(location.search, { arrayFormat: "index" });

    if (parsed.channels || parsed.genres || parsed.title) {
      if (this.hasAdvSearchTarget)
        this.advSearchTarget.classList.add("filters--filtered");
    } else {
      if (this.hasAdvSearchTarget)
        this.advSearchTarget.classList.remove("filters--filtered");
    }

    if (this.hasListTarget) {
      while (this.listTarget.firstChild) {
        this.listTarget.removeChild(this.listTarget.firstChild);
      }
      this.listTarget.classList.add("loading");
    }

    if (this.infScroll) {
      this.infScroll.destroy();
    }

    fetch(`/ajax${this.endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parsed),
    })
      .then((response) => response.text())
      .then((html) => {
        this.listTarget.parentElement.innerHTML = html;

        this.infScroll = new InfiniteScroll(this.listTarget, {
          path: `${this.endpoint}${
            location.search.length ? location.search + "&" : "?"
          }page={{#}}`,
          checkLastPage: ".pagination__next",
          append: this.listTarget.dataset.append,
          hideNav: ".pagination",
          historyTitle: true,
        });

        this.infScroll.on("request", () => {
          this.listTarget.classList.add("loading");
        });

        this.infScroll.on("load", () => {
          this.listTarget.classList.remove("loading");
        });

        this.infScroll.on("append", () => {
          this.listTarget.parentElement.dispatchEvent(
            new CustomEvent("update-ll")
          );
        });

        this.listTarget.parentElement.dispatchEvent(
          new CustomEvent("update-ll")
        );
      });
  }

  closeFilters(event) {
    if (
      event.target.closest(".movies__filter--select") ||
      event.target.closest(".movies__filter-options") ||
      event.target.closest(".movie__filter-suggested")
    ) {
      event.stopPropagation();
      return;
    }

    this.closeMobileFilters();
  }

  closeMobileFilters(event) {
    if (event && event.target.type === "search") return;

    this.filterTargets
      .filter((x) => x.classList.contains("is-active"))
      .map((x) => x.classList.remove("is-active"));
    this.optionsTargets
      .filter((x) => x.classList.contains("is-open"))
      .map((x) => x.classList.remove("is-open"));
    this.suggestionTargets
      .filter((x) => x.classList.contains("is-open"))
      .map((x) => x.classList.remove("is-open"));
  }

  closeOthers(event) {
    this.filterTargets
      .filter(
        (x) => x != event.currentTarget && x.classList.contains("is-active")
      )
      .map((x) => x.classList.remove("is-active"));
    this.optionsTargets
      .filter(
        (x) =>
          x.closest(".movies__filter").querySelector(".icon-arrow-down") !=
            event.currentTarget && x.classList.contains("is-open")
      )
      .map((x) => x.classList.remove("is-open"));
    this.suggestionTargets
      .filter(
        (x) => x != event.currentTarget && x.classList.contains("is-active")
      )
      .map((x) => x.classList.remove("is-active"));
  }
}
