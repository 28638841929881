import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
  static targets = ["sliderMovies"];

  initialize() {
    if (this.hasSliderMoviesTarget) {
      this.sliderMovies();
    }
  }

  sliderMovies() {
    this.swiperCast = new Swiper(this.sliderMoviesTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true
      },
      slidesPerView: 2,
      slidesOffsetAfter: -40,
      speed: 400,
      loop: false,
      navigation: {
        nextEl: ".movies__panel-sliderbtn--next",
        prevEl: ".movies__panel-sliderbtn--prev"
      },
        touchReleaseOnEdges: true,
        freeMode: true,
        freeModeMomentumBounce: false,
      breakpoints: {
        425: {
          slidesPerView: 3,
          slidesOffsetAfter: -40
        },
        768: {
          slidesPerView: 5,
          slidesOffsetAfter: 0
        },
        1024: {
          slidesPerView: 6,
          slidesOffsetAfter: 0
        }
      }
    });
  }
}
