import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filterYear", "filterMonth", "filterDay"];

  initialize() {
    this.year = this.data.get("year");
    this.month = this.data.get("month");
    this.day = this.data.get("day");
  }

  filter() {
    let redirectUrl = "";

    if (!parseInt(this.filterYearTarget.value)) return;

    redirectUrl += `/${this.filterYearTarget.value}`;

    if (!parseInt(this.filterMonthTarget.value)) {
      this.redirect(redirectUrl);
      return;
    }

    redirectUrl += `/${this.pan(this.filterMonthTarget.value)}`;

    if (!parseInt(this.filterDayTarget.value)) {
      this.redirect(`${redirectUrl}/`);
      return;
    }

    redirectUrl += `/${this.pan(this.filterDayTarget.value)}/`;

    this.redirect(redirectUrl);
  }

  redirect(url) {
    window.location.href = url;
  }

  pan(n) {
    if (n && n.length === 1) return `0${n}`;
    return n;
  }
}
