import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
    static targets = ["video", "videoContainer", "sliderStories", "sliderGuide", "sliderMovies", "guideSelect", "guideOptions", "sliderStars",];

    initialize() {
        if (this.hasSliderStoriesTarget) this.sliderStories();
        if (this.hasSliderGuideTarget) this.sliderGuide();
        if (this.hasSliderMoviesTarget) this.sliderMovies();
        if (this.hasSliderStarsTarget) this.sliderStars();
    }

    playFirstVideo() {
        this.videoTarget.querySelector(".newsfeed__video-label").innerText =
            "In riproduzione";
        this.videoTarget.querySelector("a").classList.remove("icon-play");
    }

    playVideo(event) {
        this.videoTargets.map(video => {
            video.querySelector(".newsfeed__video-label").innerText = "";
            video.querySelector("a").classList.add("icon-play");
        });

        event.currentTarget.classList.remove("icon-play");

        event.currentTarget.parentNode.querySelector(
            ".newsfeed__video-label"
        ).innerText = "In riproduzione";

    const ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", event.currentTarget.dataset.src);
    ifrm.style.width = "1152";
    ifrm.style.height = "648";
    ifrm.frameBorder = 0;
    ifrm.setAttribute("allowFullScreen", "");
    ifrm.setAttribute("allow", "autoplay");
    ifrm.setAttribute("referrerpolicy", "unsafe-url");

        const player = this.videoContainerTarget.querySelector(".newsfeed__video")
            .firstElementChild;

        player.parentNode.replaceChild(ifrm, player);

        this.videoContainerTarget.querySelector(
            ".newsfeed__caption-label"
        ).innerText = event.currentTarget.dataset.category;

        this.videoContainerTarget.querySelector(
            ".newsfeed__caption-title"
        ).innerText = event.currentTarget.dataset.title;

        this.videoContainerTarget.querySelector(
            ".newsfeed__caption-author"
        ).innerHTML = `${
            event.currentTarget.dataset.author
                ? `di <span>${event.currentTarget.dataset.author}<\/span> `
                : ""
            }${event.currentTarget.dataset.date}`;
    }

    sliderStories() {
        this.swiperStories = new Swiper(this.sliderStoriesTarget, {
            slidesPerView: 2,
            slidesOffsetAfter: -40,
            speed: 400,
            loop: false,
            touchReleaseOnEdges: true,
            freeMode: true,
            freeModeMomentumBounce: false,
            preloadImages: false,
            lazy: {
                loadPrevNext: true
            },
            navigation: {
                nextEl: this.sliderStoriesTarget.parentNode.querySelector('.swiper-button-next'),
                prevEl: this.sliderStoriesTarget.parentNode.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                425: {
                    slidesPerView: 3,
                    slidesOffsetAfter: -40
                },
                768: {
                    slidesPerView: 5,
                    slidesOffsetAfter: -40
                },
                1024: {
                    slidesPerView: 6,
                    slidesOffsetAfter: -40
                },
                1280: {
                    slidesPerView: 7,
                    slidesOffsetAfter: 0
                }
            }
        });
    }

    sliderGuide() {
        const prevButton = this.sliderGuideTarget.querySelector('.swiper-button-prev');
        const nextButton = this.sliderGuideTarget.querySelector('.swiper-button-next');

        this.swiperDays = new Swiper(this.sliderGuideTarget, {
            slidesPerView: 1,
            speed: 400,
            loop: false,
            roundLengths: true,
            noSwipingClass: "swiper-slide",
            navigation: {
                nextEl: nextButton,
                prevEl: prevButton
            },
            touchReleaseOnEdges: true,
            freeMode: true,
            freeModeMomentumBounce: false,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    noSwipingClass: "swiper-no-swiping"
                },
                1024: {
                    slidesPerView: 3
                }
            }
        });
    }

    sliderMovies() {
        this.swiperMovies = new Swiper(this.sliderMoviesTargets, {
            preloadImages: false,
            lazy: {
                loadPrevNext: true
            },
            slidesPerView: 2,
            slidesOffsetAfter: -40,
            speed: 400,
            loop: false,
            touchReleaseOnEdges: true,
            freeMode: true,
            freeModeMomentumBounce: false,
            noSwiping: false,
            noSwipingClass: "swiper-slide",
            breakpoints: {
                425: {
                    slidesPerView: 3,
                    slidesOffsetAfter: -40,
                    noSwiping: false
                },
                768: {
                    slidesPerView: 5,
                    slidesOffsetAfter: 0,
                    noSwiping: true
                },
                1024: {
                    slidesPerView: 6,
                    slidesOffsetAfter: 0,
                    noSwiping: true
                }
            }
        });
    }

    toggleGuideFilters(e) {
        this.guideSelectTargets
            .filter(x => x.classList.contains("is-active"))
            .map(x => x.classList.remove("is-active"));
        this.guideOptionsTargets
            .filter(x => x.classList.contains("is-open"))
            .map(x => x.classList.remove("is-open"));

        e.currentTarget.classList.add("is-active");
        e.currentTarget.parentNode.parentNode.querySelector(".guide__strip-options").classList.add('is-open');
    }

    closeGuideFilters(event) {
        if (
            event.target.closest(".guide__strip-filters")
        ) {
            event.stopPropagation();
            return;
        }

        this.guideSelectTargets
            .filter(x => x.classList.contains("is-active"))
            .map(x => x.classList.remove("is-active"));
        this.guideOptionsTargets
            .filter(x => x.classList.contains("is-open"))
            .map(x => x.classList.remove("is-open"));
    }

    sliderStars() {
        this.swiperStars = new Swiper(this.sliderStarsTargets, {
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 2,
            slidesOffsetAfter: -40,
            speed: 400,
            loop: false,
            touchReleaseOnEdges: true,
            freeMode: true,
            freeModeMomentumBounce: false,
            noSwiping: false,
            noSwipingClass: "swiper-slide",
            breakpoints: {
                600: {
                    slidesPerView: 3,
                    slidesOffsetAfter: -40,
                    noSwiping: false,
                },
                768: {
                    slidesPerView: 4,
                    slidesOffsetAfter: 0,
                    noSwiping: true,
                },
                1024: {
                    slidesPerView: 5,
                    slidesOffsetAfter: 0,
                    noSwiping: true,
                },
            },
        });
    }
}
