import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["question", "options"];

  initialize() {
    this.options = {
      hidePanelAfterSharing: false,
      countdownFrom: 5,
      countdownTimeout: 1100,
      resultsTimeout: 1000,
      shareTimeout: 2000,
      utmMedium: "social",
      utmCampaign: "poll",
      facebookShareBaseUrl: "https://www.facebook.com/sharer/sharer.php?u=",
      twitterShareBaseUrl: "https://www.facebook.com/sharer/sharer.php?u=",
      whatsappShareBaseUrl: "whatsapp://send?text=",

      classes: {
        option: "poll__option",
        numero: "poll__numero",
        countdown: "poll__countdown",
        condividi: "poll__condividi",
        ok: "poll__option--ok",
        results: "poll__options--results",
        vai: "vai",
      },
    };

    this.countdown = this.element.querySelector(
      `.${this.options.classes.countdown}`
    );
    this.sharePanel = this.element.querySelector(
      `.${this.options.classes.condividi}`
    );

    this.pollGuid = this.data.get("guid");
  }

  async selectOption(ev) {
    const option = ev.currentTarget.parentNode;
    const choiceGuid = option.dataset.guid;
    const redirectUrl = option.dataset.redirectUrl;

    this.optionsTarget.classList.remove(this.options.classes.results);

    const result = this.optionsTarget.querySelector(
      `.${this.options.classes.numero}`
    );

    if (result) {
      result.parentNode.removeChild(result);
    }

    var data = await this.votePollOnServer(choiceGuid);

    option.classList.add(this.options.classes.ok);

    var siblings = this.getSiblings(option);
    siblings.forEach((opt) => opt.classList.remove(this.options.classes.ok));

    this.optionsTarget
      .querySelectorAll(".poll__option")
      .forEach((elem, idx) => {
        this.appendVoteParagraph(elem, data.choices[idx]);
      });

    setTimeout(() => {
      this.optionsTarget.classList.add(this.options.classes.results);
    }, this.options.resultsTimeout);

    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
      setTimeout(() => {
        this.element.classList.add("vai");
        this.counter(this.options.countdownFrom);
      }, this.options.countdownTimeout);
    } else {
      setTimeout(() => {
        this.sharePanel.classList.add("open");
      }, this.options.shareTimeout);
    }
  }

  getSiblings(elem) {
    return Array.prototype.filter.call(elem.parentNode.children, function (
      sibling
    ) {
      return sibling !== elem;
    });
  }

  counter(n) {
    const loop = () => {
      this.countdown.innerText = n;

      if (n--) {
        this.timer = setTimeout(loop, 1000);
      } else {
        location.href = this.redirectUrl;
      }
    };

    loop();
  }

  appendVoteParagraph(option, choice) {
    const p = document.createElement("p");
    p.classList.add(this.options.classes.numero);
    p.innerText = choice.votes;
    p.style.visibility = "hidden";
    option.appendChild(p);

    let buttonPad = 0;

    if (choice.voted) {
      const button = document.createElement("button");
      button.classList.add("icon-x");
      button.setAttribute("data-action", "poll#closeOption");
      p.appendChild(button);

      buttonPad = button.offsetHeight / 2;
    }

    const height = option.querySelector("img").offsetHeight;
    const min = p.offsetHeight + buttonPad;
    const max = height - buttonPad - 1;

    p.style.visibility = "visible";
    p.style.height = `${min + (choice.votesPerc / 100) * (max - min)}px`;
  }

  async votePollOnServer(choiceGuid) {
    var voteGuid = Cookies.get(`poll_${this.pollGuid}`);

    const response = await fetch("/ajax/votePoll/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        guid: choiceGuid,
        voteGuid: voteGuid,
      }),
    });

    var data = await response.json();

    Cookies.set(`poll_${this.pollGuid}`, data.voteGuid, {
      path: "/",
      sameSite: "none",
      secure: location.protocol === "https:",
    });

    return data;
  }

  async unvotePollOnServer(choiceGuid) {
    var voteGuid = Cookies.get(`poll_${this.pollGuid}`);

    await fetch("/ajax/unvotePoll/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        guid: choiceGuid,
        voteGuid: voteGuid,
      }),
    });

    Cookies.set(`poll_${this.pollGuid}`, null, {
      path: "/",
      sameSite: "none",
      secure: location.protocol === "https:",
    });
  }

  closeShare() {
    this.sharePanel.classList.remove("open");
  }

  async closeOption(ev) {
    var option = ev.currentTarget.closest(`.${this.options.classes.option}`);
    const choiceGuid = option.dataset.guid;

    window.clearTimeout(this.timer);

    await this.unvotePollOnServer(choiceGuid);

    option.classList.remove(this.options.classes.ok);
    this.optionsTarget.classList.remove(this.options.classes.results);
    this.element.classList.remove("vai");
    this.countdown.innerText = this.options.countdownFrom;
    this.element.classList.remove("off");
  }

  cleanTimer() {
    window.clearTimeout(this.timer);
    this.redirectUrl = null;
    this.countdown.innerText = this.options.countdownFrom;
    this.element.classList.remove("vai");
    this.element.classList.add("off");
  }
}
