import { Controller } from "stimulus";

export default class extends Controller {
    toggle(event) {
        var parent = event.currentTarget.parentNode;
        var content = parent.querySelector('.faq__content');

        if (!parent.classList.contains('faq__item--open')) {
            parent.classList.add('faq__item--open');
            content.style.height = 'auto';
            var height = content.clientHeight + "px";
            content.style.height = '0px';
            setTimeout(()=> {
                content.style.height = height;
            }, 0);
        } else {
            content.style.height = '0px';
            content.addEventListener('transitionend', function () {
                parent.classList.remove('faq__item--open');
            }, {
                once: true
            });
        }
    }
}
