import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["parallax"];

  connect() {
    this.scheduledAnimationFrame = false;
  }

  onScroll() {
    if (this.scheduledAnimationFrame) {
      return;
    }
    this.scheduledAnimationFrame = true;
    window.requestAnimationFrame(() => this.parallax());
  }

  parallax() {
    var st = window.pageYOffset || document.documentElement.scrollTop;

    var localScroll,
      totalScroll,
      actualScroll,
      bottomWindow = st + window.innerHeight;

    var parallaxContainerTop = this.parallaxTarget.closest("figure").offsetTop;
    localScroll = bottomWindow - parallaxContainerTop;
    totalScroll = this.parallaxTarget.offsetHeight + window.innerHeight;
    actualScroll = (localScroll * 22) / totalScroll;

    if (bottomWindow > parallaxContainerTop) {
      this.parallaxTarget.style.transform = `translate(-50%,-${actualScroll}%)`;
    }

    this.scheduledAnimationFrame = false;
  }
}
