import { Controller } from "stimulus";
import CustomEvent from "custom-event";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
  static targets = [
    "swiperGallery",
    "pagination",
    "left",
    "right",
    "photo",
    "caption",
    "credits",
    "description",
    "attribution",
  ];

  connect() {
    this.swiperGallery = new Swiper(this.swiperGalleryTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 1,
      speed: 400,
      loop: false,
      touchReleaseOnEdges: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      pagination: {
        el: this.paginationTarget,
        type: "fraction",
      },
      navigation: {
        prevEl: this.leftTarget,
        nextEl: this.rightTarget,
      },
      on: {
        slideChange: () => {
          this.captionTarget.innerText = this.photoTargets[
            this.swiperGallery.activeIndex
          ].dataset.caption;
          this.creditsTarget.innerText = this.photoTargets[
            this.swiperGallery.activeIndex
          ].dataset.credits;
          this.descriptionTarget.innerText = this.photoTargets[
            this.swiperGallery.activeIndex
          ].dataset.description;
          his.attributionTarget.innerText = this.photoTargets[
            this.swiperGallery.activeIndex
          ].dataset.attribution;
        },
      },
    });
  }

  initGallery(event) {
    event.currentTarget.dispatchEvent(
      new CustomEvent("init-gallery", {
        detail: {
          photos: this.photoTargets.map((p) => {
            return {
              caption: p.dataset.caption,
              credits: p.dataset.credits,
              description: p.dataset.description,
              attribution: p.dataset.attribution,
              imageSrc: p.dataset.imageSrc,
              thumbSrc: p.dataset.thumbSrc,
            };
          }),
          index: this.swiperGallery.activeIndex,
        },
      })
    );
  }
}
