import "intersection-observer";

import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
  static targets = [
    "sliderStories",
    "sliderPosts",
    "strip",
    "stripTxtTop",
    "stripTxtBottom",
    "stripCat",
    "counter",
    "form",
    "submit",
    "confirmed",
  ];

  static values = { reCaptchaSiteKey: String };

  initialize() {
    this.isScrolling;

    this.scheduledAnimationFrame = false;

    if (this.hasSliderStoriesTarget) this.sliderStories();
    if (this.hasSliderPostsTarget) this.sliderPosts();

    this.counterObserver();
  }

  sliderStories() {
    this.swiperStories = new Swiper(this.sliderStoriesTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 1,
      slidesOffsetAfter: -130,
      speed: 400,
      loop: false,
      touchReleaseOnEdges: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      breakpoints: {
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
          slidesOffsetAfter: -110,
        },
        1024: {
          slidesPerView: 4,
          noSwipingClass: "swiper-slide",
          slidesOffsetAfter: 0,
        },
      },
    });
  }

  sliderPosts() {
    const prevButton = this.sliderPostsTarget.querySelector(
      ".swiper-button-prev"
    );
    const nextButton = this.sliderPostsTarget.querySelector(
      ".swiper-button-next"
    );

    this.swiperPosts = new Swiper(this.sliderPostsTarget, {
      slidesPerView: 1,
      noSwipingClass: "swiper-slide",
      speed: 400,
      loop: false,
      roundLengths: true,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
      touchReleaseOnEdges: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      breakpoints: {
        768: {
          slidesPerView: 2,
          noSwipingClass: "swiper-no-swiping",
        },
        1024: {
          slidesPerView: 3,
          noSwipingClass: "swiper-no-swiping",
        },
      },
    });
  }

  handleScroll() {
    if (this.scheduledAnimationFrame) {
      return;
    }
    this.scheduledAnimationFrame = true;
    window.requestAnimationFrame(() => {
      this.stripObserver();

      window.clearTimeout(this.isScrolling);

      const cat = this.stripCatTarget;

      this.isScrolling = setTimeout(function () {
        cat.classList.remove("is-animating");
      }, 200);
    });
  }

  stripObserver() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.stripEffectOnScroll(this.stripTarget);
      }
    });

    observer.observe(this.stripTarget);
  }

  stripEffectOnScroll(element) {
    var st = document.documentElement.scrollTop;

    var localScroll,
      totalScroll,
      actualScroll,
      actualScrollCat,
      bottomWindow = st + window.innerHeight;

    let catScale = getComputedStyle(document.documentElement).getPropertyValue(
      "--catScale"
    );
    let stripTxtScroll = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--stripTxtScroll");

    var parallaxContainerTop = element.offsetTop;
    localScroll = bottomWindow - parallaxContainerTop;
    totalScroll = element.offsetHeight + window.innerHeight;
    actualScroll = (localScroll * stripTxtScroll) / totalScroll;
    actualScrollCat = (localScroll * (window.innerWidth / 2)) / totalScroll;

    this.stripTxtTopTarget.style.transform = `translate(-${actualScroll}%, 0)`;
    this.stripTxtBottomTarget.style.transform = `translate(${actualScroll}%, 0)`;
    this.stripCatTarget.classList.add("is-animating");
    this.stripCatTarget.style.transform = `translate(${actualScrollCat}%, -50%) scale(${catScale})`;

    this.scheduledAnimationFrame = false;
  }

  counterObserver() {
    this.counterTargets.map((el) => {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.updateCounter(el);
          observer.unobserve(el);
        }
      });

      observer.observe(el);
    });
  }

  updateCounter(counter) {
    const start = 0;
    const end = counter.dataset.value;
    const duration = 2000;

    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      counter.innerHTML = this.commaSeparateNumber(
        Math.floor(progress * (end - start) + start)
      );
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  commaSeparateNumber(val) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
      val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "." + "$2");
    }
    return val;
  }

  async requestInfo(e) {
    e.preventDefault();

    const button = this.formTarget.querySelector(".button");
    button.classList.add("is-loading");

    if (!this.validateForm(this.formTarget)) {
      button.classList.remove("is-loading");
      return;
    }

    grecaptcha.ready(() => {
      grecaptcha
        .execute(this.reCaptchaSiteKeyValue, { action: "advertising" })
        .then(async (token) => {
          var formData = new FormData(this.formTarget);

          formData.append("Token", token);

          const response = await fetch(this.formTarget.action, {
            method: "POST",
            body: formData,
          });

          button.classList.remove("is-loading");

          this.confirmedTarget.classList.add("is-open");

          const confirmedTitle = this.confirmedTarget.querySelector(
            ".popup__title"
          );
          const confirmedText = this.confirmedTarget.querySelector(
            ".popup__text p"
          );

          if (response.status === 200) {
            confirmedTitle.classList.remove("popup__title--error");

            confirmedTitle.textContent =
              "Grazie: abbiamo ricevuto il tuo messaggio";

            confirmedText.textContent =
              "Ti contatteremo appena possibile. A presto!";
            this.formTarget.reset();
          } else {
            confirmedTitle.classList.add("popup__title--error");

            onfirmedTitle.textContent =
              "Attenzione: errore di invio!";

            confirmedText.textContent =
              "Ci spiace, ma il tuo messaggio non è stato inviato. Riprova più tardi per favore.";
          }
        });
    });
  }

  validateForm() {
    let isValid = true;
    const BreakException = {};

    const requiredFields = this.formTarget.querySelectorAll(
      "textarea:required,input:required"
    );

    try {
      requiredFields.forEach((field) => {
        if (!field.disabled && !field.value.trim()) {
          field.focus();
          isValid = false;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }

    if (!isValid) {
      return false;
    }

    const invalidFields = this.formTarget.querySelectorAll("input:invalid");

    try {
      invalidFields.forEach((field) => {
        if (!field.disabled) {
          field.focus();
          isValid = false;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }

    return isValid;
  }
}
