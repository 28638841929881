import { Controller } from "stimulus";
import { fbButton, tw, whatsapp } from "vanilla-sharing";

export default class extends Controller {
  static targets = ["shares"];

  toggleShare() {
    document
      .querySelector(".share__button")
      .classList.toggle("share__button--active");
    this.sharesTarget.classList.toggle("share--open");
  }

  fb(e) {
    e.preventDefault();
    fbButton({
      url: this.data.get("url"),
    });
  }

  tw(e) {
    e.preventDefault();
    tw({
      url: this.data.get("url"),
      title: this.data.get("title"),
    });
  }

  wa(e) {
    e.preventDefault();
    whatsapp({
      url: this.data.get("url"),
      title: this.data.get("title"),
    });
  }
}
