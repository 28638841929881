import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
    static targets = ["layer", "sliderMovies", "sliderRelateds"];

    initialize() {
        if (this.hasSliderMoviesTarget) this.sliderMovies();
        if (this.hasSliderRelatedsTarget) this.sliderRelateds();
    }

    openWishLayer() {
        event.currentTarget.dispatchEvent(new CustomEvent("body-noscroll"));
        this.layerTarget.removeAttribute("hidden");
    }

    closeWishLayer() {
        event.currentTarget.dispatchEvent(new CustomEvent("body-scroll"));
        this.layerTarget.setAttribute("hidden", true);
    }

    closeWishLayerStopPropagation(event) {
        event.stopPropagation();
    }

    sliderMovies() {
        this.swiperMovies = new Swiper(this.sliderMoviesTarget, {
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 2,
            slidesOffsetAfter: -40,
            speed: 400,
            loop: false,
            touchReleaseOnEdges: true,
            freeMode: true,
            freeModeMomentumBounce: false,
            navigation: {
                nextEl: this.sliderMoviesTarget.querySelector(".star__sliderbtn--next"),
                prevEl: this.sliderMoviesTarget.querySelector(".star__sliderbtn--prev")
            },
            breakpoints: {
                425: {
                    slidesPerView: 3,
                    slidesOffsetAfter: -40,
                },
                768: {
                    slidesPerView: 5,
                    slidesOffsetAfter: 0,
                },
                1024: {
                    slidesPerView: 6,
                    slidesOffsetAfter: 0,
                },
                1280: {
                    slidesPerView: 7,
                    slidesOffsetAfter: 0,
                },
            },
        });
    }

    sliderRelateds() {
        this.swiperRelateds = new Swiper(this.sliderRelatedsTarget, {
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerView: 2,
            slidesOffsetAfter: -40,
            speed: 400,
            loop: false,
            touchReleaseOnEdges: true,
            freeMode: true,
            freeModeMomentumBounce: false,
            navigation: {
                nextEl: this.sliderRelatedsTarget.querySelector(".star__sliderbtn--next"),
                prevEl: this.sliderRelatedsTarget.querySelector(".star__sliderbtn--prev")
            },
            breakpoints: {
                425: {
                    slidesPerView: 3,
                    slidesOffsetAfter: -40,
                },
                768: {
                    slidesPerView: 5,
                    slidesOffsetAfter: 0,
                },
            },
        });
    }
}
