import { Controller } from "stimulus";
import "intersection-observer";
import playerjs from "player.js";
import CustomEvent from "custom-event";

export default class extends Controller {
  static targets = [
    "widget",
    "sentinel",
    "fullscreen",
    "igMode",
    "player",
    "title",
    "desc",
    "date",
    "category",
    "video",
  ];

  initialize() {
    const videoAdsObserver = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.widgetTarget.classList.remove("video-widget--nested");
        this.widgetTarget.classList.add("is-active");
      } else {
        this.widgetTarget.classList.add("video-widget--nested");
        this.widgetTarget.classList.remove("is-active");
        this.widgetTarget.classList.remove("video-widget--fs");
        this.widgetTarget.classList.remove("video-widget--ig");
      }
    });

    const featuredVideoObserver = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.widgetTarget.classList.add("is-active");
        if (!this.widgetTarget.classList.contains("video-widget--ig")) {
          this.player.play();
        }
      } else {
        this.widgetTarget.classList.remove("is-active");
        this.player.pause();
      }
    });

    if (this.hasSentinelTarget) {
      videoAdsObserver.observe(this.sentinelTarget);
    }

    if (document.querySelector(".post__featured-video__sentinel")) {
      featuredVideoObserver.observe(
        document.querySelector(".post__featured-video__sentinel")
      );
    }

    this.currentId = this.data.get("currentId");
    this.nested = this.data.get("nested") === "true";
    this.player = new playerjs.Player(this.playerTarget);
    this.isFirstPlay = true;
  }

  handleFullscreenClick(e) {
    const isFullscreen = this.widgetTarget.classList.contains(
      "video-widget--fs"
    );

    if (typeof gtag !== "undefined") {
      gtag("event", `${isFullscreen ? "close" : "open"}-fullscreen`, {
        event_category: "ads-video",
        event_label: this.titleTarget.innerText,
      });
    } else {
      console.log(
        `[gtag] event: ${isFullscreen ? "close" : "open"}-fullscreen ${
          this.titleTarget.innerText
        } ads-video`
      );
    }

    this.toggleFullscreen(e);

    if (this.widgetTarget.classList.contains("video-widget--fs")) {
      this.player.unmute();
    } else {
      this.player.mute();
    }
  }

  toggleFullscreen(e) {
    if (this.widgetTarget.classList.contains("video-widget--ig")) {
      this.toggleIGMode();
      this.player.play();
    }

    this.widgetTarget.classList.toggle("video-widget--fs");

    this.fullscreenTarget.classList.toggle("icon-fullscreen");
    this.fullscreenTarget.classList.toggle("icon-fullscreen-exit");

    if (this.widgetTarget.classList.contains("video-widget--fs")) {
      this.element.dispatchEvent(new CustomEvent("body-noscroll"));
    } else {
      this.element.dispatchEvent(new CustomEvent("body-scroll"));
    }
  }

  handleIGModeClick() {
    const isIgMode = this.widgetTarget.classList.contains("video-widget--ig");

    if (typeof gtag !== "undefined") {
      gtag("event", `${isIgMode ? "maximize" : "minimize"}`, {
        event_category: "ads-video",
        event_label: this.titleTarget.innerText,
      });
    } else {
      console.log(
        `[gtag] event: ${isIgMode ? "maximize" : "minimize"} ${
          this.titleTarget.innerText
        } ads-video`
      );
    }

    this.toggleIGMode();

    this.player.pause();
  }

  pauseVideo() {
    this.player.pause();
  }

  toggleIGMode() {
    if (this.widgetTarget.classList.contains("video-widget--fs"))
      this.toggleFullscreen();

    this.widgetTarget.classList.toggle("video-widget--ig");
  }

  handleLoadVideoClick(e) {
    if (!this.widgetTarget.classList.contains("video-widget--fs"))
      this.toggleFullscreen();

    if (e.currentTarget.parentNode.dataset.id !== this.currentId) {
      this.setCurrent(e.currentTarget.parentNode.dataset.id);

      if (typeof gtag !== "undefined") {
        gtag("event", `load-video`, {
          event_category: "ads-video",
          event_label: this.titleTarget.innerText,
        });
      } else {
        console.log(
          `[gtag] event: load-video ${this.titleTarget.innerText} ads-video`
        );
      }
    }
  }

  onPlayer() {
    this.player.on("ready", () => {
      if (!this.nested || !this.isFirstPlay) {
        this.player.send({ method: "startVideo" });
      }

      this.player.on("loadedmetadata", () => {
        if (this.widgetTarget.classList.contains("video-widget--fs")) {
          this.player.unmute();
        }
      });

      this.player.on("adend", () => {
        this.igModeTarget.classList.add("is-active");
      });

      this.player.on("aderror", () => {
        this.igModeTarget.classList.add("is-active");
      });

      this.player.on("ended", () => {
        const currentIndex = this.videoTargets.findIndex((v) => {
          return v.dataset.id === this.currentId;
        });

        if (currentIndex < this.videoTargets.length - 1) {
          this.setCurrent(this.videoTargets[currentIndex + 1].dataset.id);
        } else {
          this.setCurrent(this.videoTargets[0].dataset.id);
        }
      });
    });
  }

  setCurrent(id) {
    this.currentId = id;

    this.isFirstPlay = false;

    this.videoTargets
      .find((v) => {
        return v.classList.contains("is-active");
      })
      .classList.remove("is-active");

    const current = this.videoTargets.find((v) => {
      return v.dataset.id === id;
    });

    current.classList.add("is-active");

    this.videoTargets
      .find((v) => {
        return v.classList.contains("is-first");
      })
      .classList.remove("is-first");

    this.videoTargets[this.getFirstIndex()].classList.add("is-first");

    this.titleTarget.innerText = current.dataset.title;
    this.descTarget.innerText = current.dataset.desc;
    this.dateTarget.innerText = current.dataset.date;
    this.categoryTarget.innerText = current.dataset.category;
    this.playerTarget.src = current.dataset.src;
  }

  getFirstIndex = () => {
    const currentIndex = this.videoTargets.findIndex((v) => {
      return v.dataset.id === this.currentId;
    });

    if (currentIndex <= 2) {
      return 0;
    }

    if (currentIndex > 2 && currentIndex < this.videoTargets.length - 3) {
      return currentIndex - 2;
    }

    return this.videoTargets.length - 5;
  };
}
