import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";
import playerjs from "player.js";

export default class extends Controller {
  static targets = [
    "sliderCast",
    "sliderVideo",
    "sliderImages",
    "sliderRelated",
    "trailer",
  ];

  initialize() {
    if (this.hasSliderCastTarget) {
      this.sliderCast();
    }
    if (this.hasSliderVideoTarget) {
      this.sliderVideo();
    }
    if (this.hasSliderImagesTarget) {
      this.sliderImages();
    }
    if (this.hasSliderRelatedTarget) {
      this.sliderRelated();
    }
    if (this.hasTrailerTarget) {
      this.player = new playerjs.Player("trailer");
    }
  }

  sliderCast() {
    this.swiperCast = new Swiper(this.sliderCastTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 2,
      slidesOffsetAfter: -40,
      speed: 400,
      loop: false,
      noSwiping: false,
      noSwipingClass: "swiper-slide",
        touchReleaseOnEdges: true,
        freeMode: true,
        freeModeMomentumBounce: false,
      breakpoints: {
        425: {
          slidesPerView: 3,
          slidesOffsetAfter: -40,
          noSwiping: false,
        },
        768: {
          slidesPerView: 5,
          noSwiping: true,
          slidesOffsetAfter: 0,
        },
      },
    });
  }

  sliderVideo() {
    this.swiperVideo = new Swiper(this.sliderVideoTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 1,
      slidesOffsetAfter: -40,
      speed: 400,
      loop: false,
      noSwiping: false,
      noSwipingClass: "swiper-slide",
        touchReleaseOnEdges: true,
        freeMode: true,
        freeModeMomentumBounce: false,
      breakpoints: {
        425: {
          slidesPerView: 2,
          slidesOffsetAfter: -40,
          noSwiping: false,
        },
        768: {
          slidesPerView: 2,
          slidesOffsetAfter: 0,
          noSwiping: true,
        },
      },
    });
  }

  sliderImages() {
    this.swiperPoster = new Swiper(this.sliderImagesTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 2,
      slidesOffsetAfter: -40,
      speed: 400,
      loop: false,
      noSwiping: false,
        noSwipingClass: "swiper-slide",
        touchReleaseOnEdges: true,
        freeMode: true,
        freeModeMomentumBounce: false,
      breakpoints: {
        425: {
          slidesPerView: 3,
          slidesOffsetAfter: -40,
          noSwiping: false,
        },
        768: {
          slidesPerView: 4,
          slidesOffsetAfter: 0,
          noSwiping: true,
        },
      },
    });
  }

  sliderRelated() {
    this.swiperRelated = new Swiper(this.sliderRelatedTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 2,
      slidesOffsetAfter: -40,
      speed: 400,
      loop: false,
      noSwiping: false,
        noSwipingClass: "swiper-slide",
        touchReleaseOnEdges: true,
        freeMode: true,
        freeModeMomentumBounce: false,
      breakpoints: {
        425: {
          slidesPerView: 3,
          slidesOffsetAfter: -40,
          noSwiping: false,
        },
        768: {
          slidesPerView: 4,
          slidesOffsetAfter: 0,
          noSwiping: true,
        },
      },
    });
  }

  openTrailer() {
    this.trailerTarget.classList.add("movie__intro-video--full");
    this.player.unmute();
    this.player.setCurrentTime(0);
    this.player.setLoop(false);
  }

  closeTrailer() {
    this.trailerTarget.classList.remove("movie__intro-video--full");
    this.player.mute();
    this.player.play();
    this.player.setLoop(true);
  }
}
