import { Controller } from "stimulus";
import Embedo from "embedo";

export default class extends Controller {
  static values = { url: String };

  initialize() {
    this.embedo = new Embedo({
      facebook: {
        appId: window.FbAppId,
        version: "v10.0",
        access_token: "2361533583894986|c8d828cb30cb440d989142d79f6b1364",
        xfbml: true,
        locale: "it_IT",
      },
      twitter: true,
      instagram: {
        access_token: "2361533583894986|c8d828cb30cb440d989142d79f6b1364",
      },
    });
  }

  connect() {
    this.embedo.load(this.element, this.urlValue, {
      width: 697,
      centerize: true,
      hidecaption: false,
    });
  }
}
