import { Controller } from "stimulus";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
  static targets = ["sliderNetworks", "sliderDays", "filters", "channels"];

  initialize() {
    if (this.hasSliderNetworksTarget) this.sliderNetworks();
    if (this.hasSliderDaysTarget) this.sliderDays();
  }

  sliderNetworks() {
    var activeIdx = 0;
    const $active = this.sliderNetworksTarget.querySelector(
      ".guide__channels-item--current"
    );

    if (typeof $active !== "undefined" && $active !== null) {
      activeIdx = [...$active.parentNode.children].indexOf($active);
    }

    this.swiperNetworks = new Swiper(this.sliderNetworksTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: "auto",
      slidesPerGroup: 3,
      initialSlide: activeIdx,
      speed: 400,
      loop: false,
      touchReleaseOnEdges: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      navigation: {
        nextEl: ".guide__channels-btn--next",
        prevEl: ".guide__channels-btn--prev",
      },
      breakpoints: {
        425: {
          slidesPerGroup: 4,
        },
        600: {
          slidesPerGroup: 6,
        },
        768: {
          slidesPerGroup: 10,
        },
      },
      on: {
        sliderMove: function () {
          if (Modernizr.touchevents)
            document.querySelector(".guide__channels-btn--next").style.display =
              "none";
        },
      },
    });
  }

  sliderDays() {
    const prevButton = this.sliderDaysTarget.querySelector(
      ".swiper-button-prev"
    );
    const nextButton = this.sliderDaysTarget.querySelector(
      ".swiper-button-next"
    );
    const $active = this.sliderDaysTarget.querySelector(".is-active");
    const activeIdx = $active
      ? [...$active.parentNode.parentNode.children].indexOf($active.parentNode)
      : null;

    this.swiperDays = new Swiper(this.sliderDaysTarget, {
      initialSlide: activeIdx,
      roundLengths: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 5,
      speed: 400,
      loop: false,
      touchReleaseOnEdges: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      navigation: {
        nextEl: nextButton,
        prevEl: prevButton,
      },
    });
  }

  openFilters(e) {
    e.currentTarget.classList.remove("is-anim");
    this.filtersTarget.classList.add("is-open");
  }

  closeFilters() {
    this.filtersTarget.classList.remove("is-open");
  }

  closeFiltersStop(e) {
    e.stopPropagation();
  }

  toggleShowDetails(e) {
    if (!e.currentTarget.classList.contains("is-active")) {
      if (typeof gtag !== "undefined") {
        gtag("event", "open-details", {
          event_label: "tv-guide",
        });
      } else {
        console.log("[gtag] event: open-details tv-guide");
      }
    }
    e.currentTarget.classList.toggle("is-active");
    e.currentTarget.parentNode
      .querySelector(".guide__show-details")
      .classList.toggle("is-open");
  }

  setFilter(e) {
    const control = e.currentTarget.getAttribute("aria-controls");
    e.currentTarget.parentNode
      .querySelector("[selected]")
      .removeAttribute("selected");
    e.currentTarget.setAttribute("selected", true);
    document
      .getElementById(control)
      .parentNode.querySelector("[selected]")
      .removeAttribute("selected");
    document.getElementById(control).setAttribute("selected", true);
  }

  sendEvent(e) {
    const { variation, subcategory, isEvening } = e.currentTarget.dataset;
    const action = `click-${subcategory}`;
    const category =
      isEvening === "true"
        ? "tv-guide-evening-buttons"
        : "tv-guide-home-buttons";
    const label = variation;

    if (typeof gtag !== "undefined") {
      gtag("event", action, {
        event_category: category,
        event_label: label,
      });
    } else {
      console.log(`[gtag] event: ${action} ${category} ${label}`);
    }
  }

  scrollToGuide(e) {
    e.preventDefault();
    document.getElementById("programmazione").scrollIntoView({
      behavior: "smooth",
    });
  }
}
