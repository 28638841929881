import "intersection-observer";

import { Controller } from "stimulus";
import { createBrowserHistory } from "history";

export default class extends Controller {
  static targets = [
    "mainVideo",
    "sentinal",
    "placeholder",
    "video",
    "videoContainer",
  ];

  initialize() {
    const videoObserver = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        this.mainVideoTarget.classList.add("newsfeed__item--video--sticky");
      } else {
        this.mainVideoTarget.classList.remove("newsfeed__item--video--sticky");
      }
    });

    videoObserver.observe(this.sentinalTarget);
  }

  removeSticky() {
    this.mainVideoTarget.classList.remove("newsfeed__item--video--sticky");
  }

  play(event) {
    event.preventDefault();

    const that = event.currentTarget;

    this.videoTargets.forEach((video) => {
      video.querySelector(".newsfeed__video-label").innerText = "";
      video.querySelector(".newsfeed__video > div").classList.add("icon-play");
    });

    that.querySelector(".newsfeed__video > div").classList.remove("icon-play");

    that.parentNode.querySelector(".newsfeed__video-label").innerText =
      "In riproduzione";

    const ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", that.dataset.src);
    ifrm.style.width = "1152";
    ifrm.style.height = "648";
    ifrm.frameBorder = 0;
    ifrm.setAttribute("allowFullScreen", "");
    ifrm.setAttribute("allow", "autoplay");
    ifrm.setAttribute("referrerpolicy", "unsafe-url");

    const player = this.videoContainerTarget.querySelector(
      ".newsfeed__video div"
    ).firstElementChild;

    player.parentNode.replaceChild(ifrm, player);

    this.videoContainerTarget.querySelector(
      ".newsfeed__caption-title"
    ).innerText = that.dataset.title;

    this.videoContainerTarget.querySelector(
      ".newsfeed__caption-author"
    ).innerHTML = that.dataset.date;

    this.videoContainerTarget.querySelector(
      ".newsfeed__caption-text"
    ).innerHTML = `${that.dataset.text}`;

    const captionHtml = this.videoContainerTarget.querySelector(
      ".newsfeed__caption-html"
    );
    if (captionHtml) {
      captionHtml.classList.add("is-expanded");
      captionHtml.innerHTML = `${that.dataset.html}`;
    }

    if (window.innerWidth >= 768) {
      window.scrollTo(0, 0);
    }

    const history = createBrowserHistory();
    history.replace(`/video/${that.dataset.slug}/`);
    document.title = that.dataset.title;
  }
}
