import { Controller } from "stimulus";
import InfiniteScroll from "infinite-scroll";
import SweetScroll from "sweet-scroll";

export default class extends Controller {
  static targets = ["gallery", "container", "adsContentCount"];

  initialize() {
    new SweetScroll();

    this.setNewsView(parseInt(this.data.get("id")));

    this.nextContents = JSON.parse(this.containerTarget.dataset.nextContents);
    this.relatedIndex = 0;

    if (
      this.nextContents?.length &&
      typeof IsCrossCastPreview === "undefined" // non funziona in preview
    ) {
      const nextContents = this.nextContents;

      const infScroll = new InfiniteScroll(this.containerTarget, {
        path: function () {
          return nextContents[this.loadCount]?.path;
        },
        append: ".post__article",
        fetchOptions: () => ({
          method: "POST",
          body: JSON.stringify({
            parentId: this.containerTarget.dataset.parentId,
            relatedIndex: this.relatedIndex,
            adsContentCounts: this.adsContentCountTargets.map((t) =>
              parseInt(t.value)
            ),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }),
      });

      infScroll.on("request", () => {
        this.containerTarget.classList.add("loading");
      });

      infScroll.on("load", () => {
        this.containerTarget.classList.remove("loading");
      });

      infScroll.on("error", (error, path, response) => {
        console.log(error, path, response);
      });

      infScroll.on("append", () => {
        if (typeof gtag !== "undefined") {
          gtag("event", "page_view", {
            page_title: this.nextContents[this.relatedIndex].title,
            page_path: this.nextContents[this.relatedIndex].path,
          });
        } else {
          console.log(
            "[gtag] page_view",
            this.nextContents[this.relatedIndex].title,
            this.nextContents[this.relatedIndex].path
          );
        }

        this.setNewsView(parseInt(this.nextContents[this.relatedIndex].id));

        if (typeof impactStat !== "undefined") {
          impactStat("transformLinks");
          impactStat("trackImpression");
        }

        // Shinystat
        if (typeof ssxl !== "undefined") {
          ssxl(
            "PAG=" +
              encodeURIComponent(
                `https://nospoiler.it${
                  this.nextContents[this.relatedIndex].path
                }`
              )
          );
        }

        this.relatedIndex += 1;
        this.containerTarget.dispatchEvent(new CustomEvent("update-ll"));
      });
    }
  }

  initGallery(event) {
    event.currentTarget.dispatchEvent(
      new CustomEvent("init-gallery", {
        detail: {
          photos: JSON.parse(this.galleryTarget.dataset.photos),
          index: 0,
        },
      })
    );
  }

  setNewsView(id) {
    const a = Math.floor(Math.random() * 11);
    const b = Math.floor(Math.random() * 11);

    fetch("/ajax/setNewsView/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        a: a,
        b: b,
        c: a + b,
        id: id,
      }),
    });
  }
}
