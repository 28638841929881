import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "response",
    "exists",
    "thanks",
    "email",
    "group",
    "button",
    "title",
  ];

  cleanErrors() {
    this.emailTarget.classList.remove("is-error");
  }

  async submit(event) {
    event.preventDefault();

    const email = this.emailTarget.value;

    this.buttonTarget.classList.add("is-loading");

    if (!/\S+@\S+\.\S+/.test(email)) {
      this.buttonTarget.classList.remove("is-loading");
      this.emailTarget.classList.add("is-error");
      return;
    }

    const response = await fetch("/ajax/signUpNewsletter/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        gIds: this.hasGroupTarget
          ? this.groupTargets.map((t) => t.value)
          : null,
      }),
    });

    if (!response.ok) {
      this.buttonTarget.classList.remove("is-loading");
      return;
    }

    var data = await response.json();

    if (data.success) {
      this.formTarget.classList.remove("is-active");

      this.formTarget.parentNode.style.transform = "translateX(-100%)";
      this.responseTarget.classList.add("is-active");

      if (data.exists) {
        this.titleTarget.textContent = "Oops!";
        this.existsTarget.classList.add("is-active");
      } else {
        this.titleTarget.textContent = "Un’ultima cosa…";
        this.thanksTarget.classList.add("is-active");
      }
    } else {
      this.buttonTarget.classList.remove("icon-loading");
    }
  }
}
