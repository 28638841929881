import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["continue", "answer", "answers"];

  initialize() {
    this.quizSlug = this.data.get("slug");
    if (this.data.has("guid")) this.quizGuid = this.data.get("guid");
    if (this.data.has("progress"))
      this.progress = parseInt(this.data.get("progress"));
    if (this.data.has("total")) this.total = parseInt(this.data.get("total"));
    if (this.data.has("seed")) this.seed = parseInt(this.data.get("seed"));
    if (this.data.has("nextUrl")) this.nextUrl = this.data.get("nextUrl");
    if (this.data.has("questionSlug"))
      this.questionSlug = this.data.get("questionSlug");
  }

  async duel(e) {
    const { value } = e.target;

    if (!this.loading) {
      this.loading = true;

      const response = await fetch("/ajax/duel/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizGuid: this.quizGuid,
          solutionId: value,
        }),
      });

      this.loading = false;

      var data = await response.json();

      if (data.success) {
        Cookies.set(`duel_${this.quizSlug}`, `${this.progress}|${this.seed}`, {
          path: "/",
          sameSite: "none",
          secure: location.protocol === "https:",
        });

        window.location.href = this.nextUrl;
      }
    }
  }

  choose() {
    this.continueTarget.removeAttribute("disabled");
  }

  async quiz() {
    var choice = this.answerTargets.find((target) => target.checked);

    if (!choice) return;

    if (!this.loading) {
      this.loading = true;

      this.answerTargets.forEach((a) => a.setAttribute("disabled", true));

      if (
        this.answerTargets.some((a) =>
          a.parentElement.classList.contains("quiz__answer--correct")
        )
      ) {
        this.wait = true;

        if (!choice.parentElement.classList.contains("quiz__answer--correct")) {
          choice.parentElement.classList.add("quiz__answer--wrong");
        }
      }

      this.answersTarget.classList.add("has-answered");

      const response = await fetch("/ajax/quiz/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          quizSlug: this.quizSlug,
          questionSlug: this.questionSlug,
          answerId: choice.value,
        }),
      });

      this.loading = false;

      var data = await response.json();

      if (data.success) {
        setTimeout(
          () => {
            window.location.href = data.redirectUrl;
          },
          this.wait ? 2000 : 0
        );
      }
    }
  }
}
