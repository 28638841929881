import { Controller } from "stimulus";
import CustomEvent from "custom-event";
import LazyLoad from "vanilla-lazyload";
import Swiper from "swiper/js/swiper";

export default class extends Controller {
  static targets = [
    "fullscreen",
    "swiperGallery",
    "slider",
    "list",
    "switch",
    "credits",
    "caption",
    "description",
    "attribution",
  ];

  initialize() {
    this.countChanges = 0;
    this.swiperGallery = new Swiper(this.swiperGalleryTarget, {
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
      slidesPerView: 1,
      speed: 400,
      loop: false,
      touchReleaseOnEdges: true,
      freeMode: true,
      freeModeMomentumBounce: false,
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: this.fullscreenTarget.querySelector(
          ".fullscreen__slider-pagination"
        ),
        type: "fraction",
      },
      navigation: {
        prevEl: this.fullscreenTarget.querySelector(
          ".fullscreen__slider-button--prev"
        ),
        nextEl: this.fullscreenTarget.querySelector(
          ".fullscreen__slider-button--next"
        ),
      },
      on: {
        slideChangeTransitionEnd: () => {
          const previous = this.listTarget.querySelector(
            ".fullscreen__images-item--active"
          );

          if (previous) {
            previous.classList.remove("fullscreen__images-item--active");
          }

          const selected = this.listTarget.querySelector(
            `a[data-idx="${this.swiperGallery.activeIndex + 1}"]`
          );

          if (selected) {
            selected.parentNode.classList.add(
              "fullscreen__images-item--active"
            );

            if (
              this.hasCreditsTarget &&
              this.hasCaptionTarget &&
              this.hasDescriptionTarget &&
              this.hasAttributionTarget
            ) {
              this.creditsTarget.innerText = selected.dataset.credits;
              this.captionTarget.innerText = selected.dataset.caption;
              if (selected.dataset.description) {
                this.descriptionTarget.innerText = selected.dataset.description;
              }
              if (selected.dataset.attribution) {
                this.attributionTarget.innerHTML = selected.dataset.attribution;
              }
            }
          }

          // refresh ad ogni 4 slide
          this.countChanges += 1;
          if (this.countChanges == 3) {
            if (
              typeof googletag !== "undefined" &&
              googletag.pubads &&
              this.fullscreenTarget.querySelector(".fullscreen__banner")
            ) {
              _4SDexRefresh(
                [
                  { w: 300, h: 250 },
                  { w: 300, h: 600 },
                ],
                [{ k: "pos", v: "ATF" }],
                false
              );
            }
            this.countChanges = 0;
          }
        },
        slidePrevTransitionStart: () => {
          const nextfshow = this.fullscreenTarget.querySelector(
            ".fullscreen__slider-button--nextfshow--active"
          );

          if (nextfshow) {
            nextfshow.classList.remove(
              "fullscreen__slider-button--nextfshow--active"
            );
          }
        },
        reachEnd: () => {
          const nextfshow = this.fullscreenTarget.querySelector(
            ".fullscreen__slider-button--nextfshow"
          );

          if (nextfshow) {
            nextfshow.classList.add(
              "fullscreen__slider-button--nextfshow--active"
            );
          }
        },
      },
    });

    this.ll = new LazyLoad({
      container: this.listTarget,
      callback_loaded: (element) => {
        const loader = element.closest(".loading");
        if (loader) {
          loader.classList.remove("loading");
        }
      },
      callback_error: (element) => {
        const loader = element.closest(".loading");
        if (loader) {
          loader.classList.remove("loading");
        }
      },
    });
  }

  openFullscreen(event) {
    event.preventDefault();
    event.currentTarget.dispatchEvent(new CustomEvent("body-noscroll"));
    this.swiperGallery.update();
    this.showGalleryHandler(this.switchTarget);
    this.fullscreenTarget.classList.add("fullscreen--open");
  }

  closeFullscreen(event) {
    event.currentTarget.dispatchEvent(new CustomEvent("body-scroll"));
    this.fullscreenTarget.classList.remove("fullscreen--open");
  }

  goTo(event) {
    this.showGalleryHandler(this.switchTarget);
    this.openFullscreen(event);
    this.swiperGallery.slideTo(event.currentTarget.dataset.galleryIndex, 0);
  }

  showGallery(event) {
    this.showGalleryHandler(event.currentTarget);
  }

  showGalleryHandler(target) {
    this.switchTargets.map((t) =>
      t.parentNode.classList.remove("fullscreen__button--selected")
    );
    target.parentNode.classList.add("fullscreen__button--selected");
    this.listTarget.classList.remove("fullscreen__images-list--active");
    this.sliderTarget.classList.add("fullscreen__slider--active");
  }

  showList(event) {
    this.switchTargets.map((t) =>
      t.parentNode.classList.remove("fullscreen__button--selected")
    );
    event.currentTarget.parentNode.classList.add(
      "fullscreen__button--selected"
    );
    this.sliderTarget.classList.remove("fullscreen__slider--active");
    this.listTarget.classList.add("fullscreen__images-list--active");
  }

  selectPhoto(event) {
    event.preventDefault();

    this.swiperGallery.slideTo(
      parseInt(event.currentTarget.dataset.idx) - 1,
      0
    );

    this.showGalleryHandler(this.switchTarget);
  }

  initGallery(event) {
    this.swiperGallery.removeAllSlides();

    const list = this.listTarget.querySelector("ul");
    while (list.firstChild) {
      list.removeChild(list.firstChild);
    }

    let swiperSlides = [];
    let imageItems = [];

    event.detail.photos.map((p, i) => {
      // slide
      const swiperSlide = document.createElement("li");
      swiperSlide.classList.add("swiper-slide");
      const fullscreenImage = document.createElement("div");
      fullscreenImage.classList.add("fullscreen__image");
      const image = document.createElement("img");
      image.classList.add("swiper-lazy");
      image.setAttribute("data-src", p.imageSrc);
      const swiperPreloader = document.createElement("div");
      swiperPreloader.classList.add("swiper-lazy-preloader");

      fullscreenImage.appendChild(image);
      fullscreenImage.appendChild(swiperPreloader);
      swiperSlide.appendChild(fullscreenImage);

      swiperSlides.push(swiperSlide);

      // grid list
      const imageItem = document.createElement("li");
      imageItem.classList.add("fullscreen__images-item");
      imageItem.classList.add("loading");

      if (i == 0) {
        imageItem.classList.add("fullscreen__images-item--active");

        if (
          this.hasCreditsTarget &&
          this.hasCaptionTarget &&
          this.hasDescriptionTarget &&
          this.hasAttributionTarget
        ) {
          this.creditsTarget.innerText = p.credits;
          this.captionTarget.innerText = p.caption;
          if (p.description) {
            this.descriptionTarget.innerText = p.description;
          }
          if (p.attribution) {
            this.attributionTarget.innerHTML = p.attribution;
          }
        }
      }

      const selector = document.createElement("a");
      selector.setAttribute("href", "#");
      selector.setAttribute("data-idx", i + 1);
      selector.setAttribute("data-caption", p.caption);
      selector.setAttribute("data-credits", p.credits);
      if (p.description) {
        selector.setAttribute("data-description", p.description);
      }
      if (p.attribution) {
        selector.setAttribute("data-attribution", p.attribution);
      }
      selector.setAttribute("data-action", "gallery#selectPhoto");
      const thumb = document.createElement("img");
      thumb.classList.add("lazy");
      thumb.setAttribute("data-src", p.thumbSrc);

      selector.appendChild(thumb);
      imageItem.appendChild(selector);

      imageItems.push(imageItem);
    });

    this.swiperGallery.appendSlide(swiperSlides);
    list.append(...imageItems);

    if (event.detail.index > 0) {
      this.swiperGallery.slideTo(event.detail.index, 0);
    }

    this.swiperGallery.lazy.load();
    this.ll.update();
  }
}
