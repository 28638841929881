import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["textToExpand"];

    readMore(event) {
        event.target.parentNode.removeChild(event.target);
        this.textToExpandTarget.classList.add("is-expanded");
    }
}
