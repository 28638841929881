import { Controller } from "stimulus";
import CustomEvent from "custom-event";
import InfiniteScroll from "infinite-scroll";

export default class extends Controller {
  static targets = ["container"];

  initialize() {
    if (document.querySelector(".pagination__next")) {
      this.append = ".newsfeed__row";

      if (this.data.has("append")) {
        this.append = this.data.get("append");
      }

      if (this.data.has("button")) {
        this.button = this.data.get("button");
      }

      if (this.data.has("buttonEventLabel")) {
        this.buttonEventLabel = this.data.get("buttonEventLabel");
      }

      this.history = "replace";

      if (this.data.has("history")) {
        this.history = this.data.get("history");
      }

      var infScroll = new InfiniteScroll(this.containerTarget, {
        path: ".pagination__next",
        append: this.append,
        hideNav: ".pagination",
        history: this.history,
        historyTitle: true,
        button: this.button,
        loadOnScroll: typeof this.button === "undefined",
      });

      infScroll.on("request", () => {
        this.containerTarget.classList.add("loading");
        if (typeof this.button !== "undefined" && typeof gtag !== "undefined") {
          gtag("event", "load_more_button", {
            event_label: this.buttonEventLabel,
          });
        }
      });

      infScroll.on("load", () => {
        this.containerTarget.classList.remove("loading");
      });

      infScroll.on("append", () => {
        this.containerTarget.dispatchEvent(new CustomEvent("update-ll"));
      });
    }
  }
}
