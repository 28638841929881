import { Controller } from "stimulus";
import "intersection-observer";

export default class extends Controller {
    static targets = ["banner", "sentinal", "content"];

    initialize() {
        if (this.hasBannerTarget) {
            this.scheduledAnimationFrame = false;

            const bannerObserver = new IntersectionObserver(entries => {
                if (!entries[0].isIntersecting) {
                    this.bannerTarget.classList.add("is-sticky");
                } else {
                    this.bannerTarget.classList.remove("is-sticky");
                }
            });

            bannerObserver.observe(this.sentinalTarget);
        }
    }

    onScroll() {
        if (this.hasBannerTarget) {
            if (this.scheduledAnimationFrame) {
                return;
            }
            this.scheduledAnimationFrame = true;
            window.requestAnimationFrame(() => this.scrollAd());
        }
    }

    scrollAd() {
        if (this.contentTarget.getBoundingClientRect().bottom < this.bannerTarget.offsetHeight + 60)
            this.bannerTarget.classList.add("is-sticky-stop");
        else
            this.bannerTarget.classList.remove("is-sticky-stop");


        this.scheduledAnimationFrame = false;
    }
}
