import { Controller } from "stimulus";
import queryString from "query-string";
import { createBrowserHistory } from "history";
import CustomEvent from "custom-event";
import noUiSlider from "nouislider";
import wNumb from "wnumb";

export default class extends Controller {
  static targets = [
    "filter",
    "options",
    "text",
    "field",
    "item",
    "filtered",
    "search",
    "list",
    "suggestion",
    "query",
    "slider",
    "minYear",
    "maxYear",
  ];

  connect() {
    if (this.hasItemTarget) {
      this.appliedItems = this.itemTargets.filter((i) =>
        i.classList.contains("is-applied")
      );
    }
    this.searchTimeout = null;

    if (this.hasSliderTarget) this.initRangeSlider();
  }

  reset() {
    this.appliedItems = [];
    this.itemTargets.map((i) => i.classList.remove("is-selected"));

    while (this.filteredTarget.firstChild) {
      this.filteredTarget.removeChild(this.filteredTarget.firstChild);
    }
  }

  resetText() {
    this.queryTarget.value = null;
  }

  resetOne() {
    this.textTarget.innerText = "Tutti";

    while (this.listTarget.children.length > 1) {
      this.listTarget.removeChild(this.listTarget.lastElementChild);
    }

    this.listTarget.firstElementChild.firstElementChild.classList.add(
      "is-selected"
    );
  }

  resetSingle() {
    this.textTarget.innerText = "Tutti";

    var selected = this.optionsTarget.querySelector(".is-selected");

    if (selected) {
      selected.classList.remove("is-selected");
    }

    this.optionsTarget
      .querySelector("li:first-child > button")
      .classList.add("is-selected");
  }

  resetRange() {
    this.textTarget.innerText = "Tutti";

    this.sliderTarget.noUiSlider.set([
      this.sliderTarget.dataset.startMinYear,
      this.sliderTarget.dataset.startMaxYear,
    ]);
  }

  filterList(e) {
    clearTimeout(this.searchTimeout);

    if (e.key === "Enter") {
      e.preventDefault();
      return;
    }

    if (!this.searchTarget.value) return;

    this.searchTimeout = setTimeout(() => {
      fetch(`/ajax${this.searchTarget.dataset.url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: this.searchTarget.value,
        }),
      })
        .then((response) => response.text())
        .then((html) => {
          this.listTarget.innerHTML = html;
        });
    }, 500);
  }

  toggleFilter(event) {
    if (this.appliedItems) {
      this.itemTargets.map((i) => i.classList.remove("is-selected"));
      this.appliedItems.map((i) => i.classList.add("is-selected"));
    }

    this.filterTargets
      .filter((x) => x.classList.contains("is-active"))
      .map((x) => x.classList.remove("is-active"));
    this.optionsTargets
      .filter((x) => x.classList.contains("is-active"))
      .map((x) => x.classList.remove("is-open"));

    if (this.optionsTarget.classList.contains("is-open")) {
      event.currentTarget.classList.remove("is-active");
      this.optionsTarget.classList.remove("is-open");
    } else {
      event.currentTarget.classList.add("is-active");
      this.optionsTarget.classList.add("is-open");
    }
  }

  selectOption(event) {
    var selected = event.currentTarget
      .closest("ul")
      .querySelector(".is-selected");

    if (selected) {
      selected.classList.remove("is-selected");
    }

    event.currentTarget.classList.add("is-selected");
    this.textTarget.innerText = event.currentTarget.innerText;
    this.optionsTarget.classList.remove("is-open");
    this.fieldTarget.value = event.currentTarget.dataset.filterId;
  }

  selectMulti(event) {
    if (event.currentTarget.classList.contains("is-selected"))
      event.currentTarget.classList.remove("is-selected");
    else event.currentTarget.classList.add("is-selected");
  }

  applyOne(event) {
    var selected = event.currentTarget
      .closest("ul")
      .querySelector(".is-selected");

    if (selected) {
      selected.classList.remove("is-selected");
      selected.classList.remove("is-applied");
    }

    event.currentTarget.classList.add("is-selected");

    this.applyFilter();
  }

  applyFilter() {
    const history = createBrowserHistory();
    const location = history.location;
    const parsed = queryString.parse(location.search, { arrayFormat: "index" });

    this.appliedItems = this.itemTargets.filter((i) =>
      i.classList.contains("is-selected")
    );

    var selected = this.appliedItems.map((i) => {
      i.classList.add("is-applied");
      return {
        id: i.dataset.filterId,
        text: i.innerText,
      };
    });

    parsed[this.data.get("type")] = selected.map((s) => s.id);

    history.replace({
      search: queryString.stringify(parsed, { arrayFormat: "index" }),
    });

    this.filterTarget.dispatchEvent(new CustomEvent("filter-changed"));

    while (this.filteredTarget.firstChild) {
      this.filteredTarget.removeChild(this.filteredTarget.firstChild);
    }

    if (!selected.length) {
      this.filterTarget.classList.remove("is-active");
      this.optionsTarget.classList.remove("is-open");
      return;
    }

    selected.map((s) => {
      var filterItem = document.createElement("div");
      var closeButton = document.createElement("button");
      closeButton.classList.add("icon-close");
      closeButton.setAttribute("data-filter-id", s.id);
      closeButton.setAttribute("data-action", "filter#removeFilter");
      var filterText = document.createElement("span");
      filterText.innerText = s.text;
      filterItem.appendChild(closeButton);
      filterItem.appendChild(filterText);
      if (this.hasFilteredTarget) this.filteredTarget.appendChild(filterItem);
    });

    this.optionsTarget.classList.remove("is-open");
    this.filterTarget.classList.remove("is-active");
  }

  removeFilter(event) {
    const history = createBrowserHistory();
    const location = history.location;
    const id = event.currentTarget.dataset.filterId;
    const type = this.data.get("type");
    const parsed = queryString.parse(location.search, { arrayFormat: "index" });

    if (Array.isArray(parsed[type])) {
      parsed[type].pop(id);
    } else {
      parsed[type] = "";
    }

    history.replace({
      search: queryString.stringify(parsed, { arrayFormat: "index" }),
    });

    this.filterTarget.dispatchEvent(new CustomEvent("filter-changed"));

    var applied = this.itemTargets.filter((i) =>
      i.classList.contains("is-applied")
    );

    applied
      .find((i) => i.dataset.filterId === id)
      .classList.remove("is-applied", "is-selected");

    this.appliedItems = this.itemTargets.filter((i) =>
      i.classList.contains("is-applied")
    );

    var removed = event.currentTarget.closest("div");
    removed.parentElement.removeChild(removed);
  }

  applyTextFilter(e) {
    clearTimeout(this.searchTimeout);

    const el = e.currentTarget;

    if (e.key === "Alt" || e.key === "Enter" || e.key === "Control") {
      e.preventDefault();
      return;
    }

    if (!el.value) {
      this.triggerTextSearch(this.data.get("type"), el);
      return;
    }

    this.searchTimeout = setTimeout(() => {
      fetch(`/ajax${this.data.get("url")}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: el.value,
        }),
      })
        .then((response) => response.text())
        .then((html) => {
          if (html) {
            this.suggestionTarget.innerHTML = html;
            this.suggestionTarget.classList.add("is-open");
          } else {
            this.suggestionTarget.classList.remove("is-open");
          }

          this.triggerTextSearch(this.data.get("type"), el);
        });
    }, 500);
  }

  applyRangeFilter() {
    const history = createBrowserHistory();
    const location = history.location;
    const parsed = queryString.parse(location.search, { arrayFormat: "index" });

    this.textTarget.innerText = `${this.minYearTarget.innerText} - ${this.maxYearTarget.innerText}`;

    parsed["min_year"] = this.minYearTarget.innerText;
    parsed["max_year"] = this.maxYearTarget.innerText;

    history.replace({
      search: queryString.stringify(parsed, { arrayFormat: "index" }),
    });

    this.optionsTarget.classList.remove("is-open");

    this.filterTarget.dispatchEvent(new CustomEvent("filter-changed"));
  }

  selectSuggestion(e) {
    e.preventDefault();
    const el = e.currentTarget;
    this.queryTarget.value = el.innerText;
    this.suggestionTarget.classList.remove("is-open");
    this.triggerTextSearch(this.data.get("type"), this.queryTarget);
  }

  triggerTextSearch(type, el) {
    const history = createBrowserHistory();
    const location = history.location;
    const parsed = queryString.parse(location.search, {
      arrayFormat: "index",
    });

    parsed[type] = el.value;

    history.replace({
      search: queryString.stringify(parsed, { arrayFormat: "index" }),
    });

    el.dispatchEvent(new CustomEvent("filter-changed"));
  }

  selectOne(event) {
    var selected = event.currentTarget
      .closest("ul")
      .querySelector(".is-selected");

    if (selected) {
      selected.classList.remove("is-selected");
    }

    event.currentTarget.classList.add("is-selected");
    this.textTarget.innerText = event.currentTarget.innerText;
    this.optionsTarget.classList.remove("is-open");

    const history = createBrowserHistory();
    const location = history.location;
    const parsed = queryString.parse(location.search, {
      arrayFormat: "index",
    });

    parsed[this.data.get("type")] = event.currentTarget.dataset.filterId;

    history.replace({
      search: queryString.stringify(parsed, { arrayFormat: "index" }),
    });

    this.textTarget.dispatchEvent(new CustomEvent("filter-changed"));
  }

  initRangeSlider() {
    noUiSlider.create(this.sliderTarget, {
      start: [
        this.sliderTarget.dataset.startMinYear,
        this.sliderTarget.dataset.startMaxYear,
      ],
      connect: true,
      format: wNumb({
        decimals: 0,
      }),
      range: {
        min: parseInt(this.sliderTarget.dataset.startMinYear),
        max: parseInt(this.sliderTarget.dataset.startMaxYear),
      },
    });

    this.sliderTarget.noUiSlider.on("update", (values) => {
      this.minYearTarget.innerText = values[0];
      this.maxYearTarget.innerText = values[1];
    });

    if (this.sliderTarget.dataset.minYear && this.sliderTarget.dataset.maxYear)
      this.sliderTarget.noUiSlider.set([
        this.sliderTarget.dataset.minYear,
        this.sliderTarget.dataset.maxYear,
      ]);
  }
}
