import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.header = this.element;
    if (window.innerWidth >= 768) {
        this.headerHeight = this.header.offsetHeight;
    } else {
        this.headerHeight = this.header.querySelector('.header__main').offsetHeight;
    }
    this.scheduledAnimationFrame = false;
    this.lastScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
    this.mainVideo = document.querySelector(".newsfeed__item--video");
    this.channels = document.querySelector(".guide__channels");
  }

  onScroll() {
    if (this.scheduledAnimationFrame) {
      return;
    }

    this.scheduledAnimationFrame = true;
    window.requestAnimationFrame(() => this.scrollNavHeader());
  }

  scrollNavHeader() {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    var advTop = 0;

    if (document.querySelector(".adv__top"))
      advTop = document.querySelector(".adv__top").offsetHeight;

    if (st > this.headerHeight + advTop) {
      this.header.classList.add("app__header--sticky");

      if (st > this.lastScrollTop) {
        this.header.classList.remove("is-active");

        if (this.mainVideo) {
          this.mainVideo.classList.remove(
            "newsfeed__item--video--sticky--down"
          );
        }

        if (this.channels) {
          this.channels.classList.remove("guide__channels--sticky--down");
        }
      } else {
        this.header.classList.add("is-active");

        if (this.mainVideo) {
          this.mainVideo.classList.add("newsfeed__item--video--sticky--down");
        }

        if (this.channels) {
          this.channels.classList.add("guide__channels--sticky--down");
        }
      }
    } else {
      this.header.classList.remove("app__header--sticky", "is-active");

      if (this.mainVideo) {
        this.mainVideo.classList.remove("newsfeed__item--video--sticky--down");
      }

      if (this.channels) {
        this.channels.classList.remove("guide__channels--sticky--down");
      }
    }

    this.lastScrollTop = st <= 0 ? 0 : st;
    this.scheduledAnimationFrame = false;
  }
}
