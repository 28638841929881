import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  static values = { id: Number, title: String };

  submit(e) {
    const target = e.currentTarget;

    fetch("/ajax/vote/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.idValue,
        rate: parseInt(target.dataset.rate),
      }),
    });

    target.classList.add("is-animating");
    target.querySelector("span").classList.add("icon-thumb-full");
    target.parentNode.classList.add("voted");

    if (typeof gtag !== "undefined") {
      gtag("event", `vote`, {
        event_label: this.titleValue,
      });
    } else {
      console.log(`[gtag] event: vote ${this.titleValue}`);
    }
  }
}
