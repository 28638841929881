import { Controller } from "stimulus";

export default class extends Controller {
  reveals(event) {
    this.delay = 0;

    if (document.documentElement.classList.contains("touchevents"))
      this.delay = 800;

    setTimeout(() => {
      this.element.classList.remove("post__spoiler--active");
      this.element.querySelector("div").classList.add("-open");
      event.target.remove();
    }, this.delay);
  }
}
