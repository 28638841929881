import { Controller } from "stimulus";

export default class extends Controller {
  play(event) {
    event.preventDefault();
    
    const ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", this.data.get("src"));
    ifrm.style.width = "654";
    ifrm.style.height = "368";
    ifrm.frameBorder = 0;
    ifrm.setAttribute("allowFullScreen", "");
    ifrm.setAttribute("allow", "autoplay");
    ifrm.setAttribute("referrerpolicy", "unsafe-url");

    event.currentTarget.parentNode.parentNode.classList.add('newsfeed__video--playing');
    event.currentTarget.parentNode.replaceChild(ifrm, event.currentTarget);
  }
}
